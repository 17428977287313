import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  SEO,
  Container,
  ParallaxContainer,
  SmallContainer,
  Header,
  TestimonialsCarousel,
  Tiles,
  KitchenStyles
} from 'components';
import styled from 'styled-components';
import { P, H2 } from '../styles/mixins';
import SocialBanner from 'assets/images/social-banner.jpg';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    parallaxImage: file(relativePath: { eq: "assets/images/our-story.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50, layout: FULL_WIDTH)
      }
    }
    slides: allSanitySlide(sort: { fields: orderNumber }) {
      edges {
        node {
          orderNumber
          caption
          slideImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          projectLink {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data } = props;
  const { site, parallaxImage } = data || {};

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <main>
      <SEO
        title="Bespoke Kitchens & Fitted Furniture"
        description={site.description}
        keywords={site.keywords}
        image={SocialBanner}
      />
      <div id="home"></div>
      <Header />
      <div id="story"></div>
      <SmallContainer>
        <H2 style={{ textAlign: 'center', marginTop: '3rem' }}>Our Story</H2>
        <P style={{ textAlign: 'center', marginBottom: '5rem' }}>
          With Colin himself being a Fifth Generation Cabinet Maker on his father’s side, there is
          heritage at the core of the company. Starting business in 2005 at 21, the company has a
          wealth of experience within the Kitchen and Custom Fitted Furniture industry.
        </P>
      </SmallContainer>
      <ParallaxContainer image={parallaxImage.childImageSharp.gatsbyImageData}>
        <SmallContainer>
          <H2 style={{ textAlign: 'center', color: 'white' }}>Our Mission</H2>
          <P style={{ textAlign: 'center', color: 'white' }}>
            As a family run business, our objective is clear. We are passionate about what we do and
            are dedicated to delivering well designed, well thought out and well executed Kitchens
            and Interiors.
          </P>
        </SmallContainer>
      </ParallaxContainer>
      <SmallContainer>
        <P
          style={{
            textAlign: 'center',
            margin: '3rem auto'
          }}>
          Here at Colin Maher Design we are passionate about what we do and we emphasise quality
          over quantity. We have little interest in the mass market, generic kitchen industry. We
          build exquisite, unique kitchens and custom furniture to suit your taste and to suit your
          home.
        </P>
        <P style={{ textAlign: 'center', marginBottom: '5rem' }}>
          From our family to yours - <strong>Colin Maher</strong>
        </P>
      </SmallContainer>
      <div id="kitchens"></div>
      <H2 style={{ textAlign: 'center' }}>Kitchen Styles</H2>
      <KitchenStyles />
      <SmallContainer>
        <P
          style={{
            textAlign: 'center'
          }}>
          Need help deciding which kitchen style will best suit your home and your taste. Have a
          browse through our recent kitchen projects by clicking on a style above.
        </P>
      </SmallContainer>
      <Container>
        <Tiles />
      </Container>
      <SmallContainer>
        <H2 style={{ textAlign: 'center', marginTop: '5rem' }}>Testimonials</H2>
        <TestimonialsCarousel />
      </SmallContainer>
    </main>
  );
};

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  min-height: 350px;
  position: relative !important;
  object-position: center !important;
`;

export default IndexPage;
